import { Navigate, RouteObject } from 'react-router';
import { Configuration } from 'views/Configuration/Configuration';
import { DataSafety } from 'views/DataSafety/DataSafety';
import { Imprint } from 'views/Imprint/Imprint';

import Dashboard from '../../views/Dashboard/Dashboard';
import { RouteConfig } from './RouteConfig';

export const MainRouteConfig: RouteObject[] = [
    { path: RouteConfig.Main.root, element: <Dashboard /> },
    { path: RouteConfig.Main.configuration.root, element: <Configuration /> },
    // { path: RouteConfig.Main.page.self, element: <FacilityView /> },
    { path: RouteConfig.Main.impressum.root, element: <Imprint /> },
    { path: RouteConfig.Main.datenschutz.root, element: <DataSafety /> },

    // { path: RouteConfig.Main.allergen.root, element: <AllergenView /> },
    // {
    //     path: RouteConfig.Main.dish.root,
    //     children: [
    //         { path: RouteConfig.Main.dish.root, element: <DishView /> },
    //         { path: RouteConfig.Main.dish.self, element: <DishViewSelf /> },
    //     ],
    // },
    { path: '*', element: <Navigate to={RouteConfig.Main.root} /> },
];
