import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { Typography } from '@mui/material';
import Icon from 'components/Icon';

interface ILPChecklistItem {
    text?: string;
}

export const LPChecklistItem = (props: ILPChecklistItem) => {
    const { text = 'Lorem ipsum dolor sit amet.' } = props;
    return (
        <div className="flex row align-items-center">
            <div
                className="flex justify-content-center align-items-center spacing margin right double"
                style={{ backgroundColor: '#2fa155', color: '#fff', padding: 5, borderRadius: 100 }}
            >
                <Icon icon={faCheck} style={{ fontSize: 15 }} />
            </div>

            <Typography>{text}</Typography>
        </div>
    );
};
