import { createEmptyEntityCollection, IEntity, IEntityCollection } from '@intermedia/core/models/core/entity';

import { ICustomLink } from '../customLink/ICustomLink';
import { IPrice } from '../price/IPrice';

export interface IFacility extends IEntity {
    name: string;
    description: string;

    primaryColor: string;
    secondaryColor: string;

    facebookLink?: string;
    instagramLink?: string;
    twitterLink?: string;

    wlanName?: string;
    wlanCode?: string;

    customLinks: IEntityCollection<ICustomLink>;
    prices: IEntityCollection<IPrice>;
}

export const defaultFacility: IFacility = {
    name: '',
    description: '',
    primaryColor: '',
    secondaryColor: '',
    facebookLink: '',
    instagramLink: '',
    twitterLink: '',
    wlanCode: '',
    wlanName: '',
    customLinks: createEmptyEntityCollection(),
    prices: createEmptyEntityCollection(),
    id: '',
};
