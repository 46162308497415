import './LandingpageWrapper.scss';

import React from 'react';

interface ILandingpageWrapperProps {
    children: JSX.Element;
    style?: React.CSSProperties | undefined;
    wrapperStyle?: React.CSSProperties | undefined;
    className?: string | undefined;
    wrapperClassName?: string | undefined;
}

export const LPSectionWrapper = (props: ILandingpageWrapperProps) => {
    const { children, style, className, wrapperClassName, wrapperStyle } = props;
    return (
        <div className={`lp-wrapper ${wrapperClassName}`} style={wrapperStyle}>
            <div className={`inner-content ${className}`} style={style}>
                {children}
            </div>
        </div>
    );
};
