import FetchService, { HttpMethods } from '@intermedia/core/FetchService';
import { ActionCreator, IBaseAction } from '@intermedia/core/StoreManager';
import ApiRoutes from '../config/ApiRoutes';
import {
    ILoginCredentials,
    IPasswordChangeCredentials,
    IPasswordResetCredentials,
    IRegisterCredentials,
} from '../models/contracts/requests';
import { IUser } from '../models/domain/identity';
import { DeclareAsyncAction } from '../models/state/AsyncActionLiteralCreatorType';
import { createAsyncActionEnum } from '../models/state/createAsyncActionEnum';

export const AuthenticationActions = {
    ...createAsyncActionEnum('SUBMIT_LOGIN'),
    ...createAsyncActionEnum('SUBMIT_LOGOUT'),
    ...createAsyncActionEnum('SUBMIT_REGISTER'),
    ...createAsyncActionEnum('CHANGE_PASSWORD'),
    ...createAsyncActionEnum('RESET_PASSWORD'),
    ...createAsyncActionEnum('RESEND_CONFIRM_EMAIL'),
    SESSION_EXPIRED: 'SESSION_EXPIRED',
} as const;

type ISessionExpiredAction = IBaseAction<typeof AuthenticationActions.SESSION_EXPIRED>;

export type AuthenticationAction =
    | DeclareAsyncAction<typeof AuthenticationActions.SUBMIT_LOGIN, IUser>
    | DeclareAsyncAction<typeof AuthenticationActions.SUBMIT_LOGOUT>
    | DeclareAsyncAction<typeof AuthenticationActions.SUBMIT_REGISTER, IUser>
    | DeclareAsyncAction<typeof AuthenticationActions.CHANGE_PASSWORD, undefined, IUser>
    | DeclareAsyncAction<typeof AuthenticationActions.RESET_PASSWORD, undefined, IUser>
    | DeclareAsyncAction<typeof AuthenticationActions.RESEND_CONFIRM_EMAIL>
    | ISessionExpiredAction;

export class AuthenticationActionCreator {
    public static submitLogin(credentials: ILoginCredentials) {
        const href = ApiRoutes.createUrl(ApiRoutes.Identity.Login);

        return ActionCreator.createAsyncActionByType(AuthenticationActions.SUBMIT_LOGIN, () =>
            FetchService.fetch(href, credentials, HttpMethods.POST)
        );
    }

    public static submitLogout() {
        const href = ApiRoutes.createUrl(ApiRoutes.Identity.Logout);

        return ActionCreator.createAsyncActionByType(AuthenticationActions.SUBMIT_LOGOUT, () =>
            FetchService.fetch(href, undefined, HttpMethods.POST)
        );
    }

    public static register(credentials: IRegisterCredentials) {
        const href = ApiRoutes.createUrl(ApiRoutes.Identity.Register);

        return ActionCreator.createAsyncActionByType(AuthenticationActions.SUBMIT_REGISTER, () =>
            FetchService.fetch(href, credentials, HttpMethods.POST)
        );
    }

    public static changePassword(user: IUser, passwordChangeCredentials: IPasswordChangeCredentials) {
        const href = ApiRoutes.createUrl(ApiRoutes.Identity.ChangePassword);

        return ActionCreator.createAsyncActionByType(
            AuthenticationActions.CHANGE_PASSWORD,
            () => FetchService.fetch(href, passwordChangeCredentials, HttpMethods.PATCH, undefined),
            user
        );
    }

    public static resetPassword(passwordResetCredentials: IPasswordResetCredentials) {
        const href = ApiRoutes.createUrl(ApiRoutes.Identity.ResetPassword);

        return ActionCreator.createAsyncActionByType(AuthenticationActions.RESET_PASSWORD, () =>
            FetchService.fetch(href, passwordResetCredentials, HttpMethods.POST)
        );
    }

    public static ResendConfirmEmail(userId: string) {
        const href = ApiRoutes.createUrl(ApiRoutes.Identity.ResendVerificationEmail(userId));

        return ActionCreator.createAsyncActionByType(AuthenticationActions.RESEND_CONFIRM_EMAIL, () =>
            FetchService.fetch(href, userId, HttpMethods.POST)
        );
    }

    public static sessionExpired(): ISessionExpiredAction {
        return ActionCreator.createAction(AuthenticationActions.SESSION_EXPIRED);
    }
}

export default AuthenticationActionCreator;
