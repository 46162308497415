import './SideBar.scss';

import { faChartPie, faPencilRuler } from '@fortawesome/pro-light-svg-icons';
import { createTheme, List, ListItem, ListItemIcon, ListItemText, ThemeProvider } from '@mui/material';
import { deDE } from '@mui/material/locale';
import { Box } from '@mui/system';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import Icon from '../../../components/Icon';
import { RouteConfig } from '../../../config/routes/RouteConfig';
import useHistoryPush from '../../../core/useHistoryPush';
import { DarkTheme } from '../../../theme/DarkTheme';

export interface ISideBarProps {
    isOpen: boolean;
    logo: string;
    handleClose(): void;
}
export const SideBar = (props: ISideBarProps) => {
    const muiTheme = useMemo(() => createTheme(DarkTheme, deDE), []);
    const { logo } = props;

    const handleNavigateDashboard = useHistoryPush(RouteConfig.Main.root);

    return (
        <ThemeProvider theme={muiTheme}>
            <Box sx={{ backgroundColor: '#2FA155', p: 1 }}>
                <div className="flex justify-content-center align-items-center spacing margin top bottom triple">
                    <img
                        draggable={false}
                        src={logo}
                        alt="app-logo"
                        onClick={handleNavigateDashboard}
                        style={{
                            // cursor: 'pointer',
                            // marginRight: 72,
                            // marginLeft: 72,
                            // marginTop: 16,
                            // marginBottom: 32,
                            // maxWidth: 128,
                            // userSelect: 'none',
                            height: 30,
                        }}
                    />
                </div>

                <List className="list-root">
                    <ListItem className="list-item" button component={Link} to={RouteConfig.Main.root}>
                        <ListItemIcon>
                            <Icon icon={faChartPie} />
                        </ListItemIcon>
                        <ListItemText primary="Dashboard" />
                    </ListItem>
                    <ListItem className="list-item" button component={Link} to={RouteConfig.Main.configuration.root}>
                        <ListItemIcon>
                            <Icon icon={faPencilRuler} />
                        </ListItemIcon>
                        <ListItemText primary="Meine Seite" />
                    </ListItem>
                </List>
            </Box>
        </ThemeProvider>
    );
};

export default SideBar;
