import { Button } from '@mui/material';

import LogoOnly from '../../../../assets/images/logos/LogoFull.svg';
import { LPSectionWrapper } from '../LandingpageWrapper/LandingpageWrapper';

interface ILPHeaderProps {
    onClick(): void;
}

export const LPHeader = (props: ILPHeaderProps) => {
    const { onClick } = props;

    return (
        <LPSectionWrapper className="flex row justify-content-space-between">
            <>
                <div>
                    <img
                        draggable={false}
                        src={LogoOnly}
                        alt="app-logo"
                        onClick={() => null}
                        style={{
                            // cursor: 'pointer',
                            // marginRight: 72,
                            // marginLeft: 72,
                            // marginTop: 16,
                            // marginBottom: 32,
                            // maxWidth: 128,
                            // userSelect: 'none',
                            height: 30,
                        }}
                    />
                </div>
                <div></div>
                <div>
                    <Button variant="outlined" className="button-cta" style={{ color: '#2fa155', borderColor: '#2fa155' }} onClick={onClick}>
                        Kostenloses Beratungsgespräch
                    </Button>
                </div>
            </>
        </LPSectionWrapper>
    );
};
