import { faEye, faEyeSlash } from '@fortawesome/pro-light-svg-icons';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { memo, useState } from 'react';
import Icon from '../Icon';
import TextField, { ITextFieldProps } from '../TextField';

export interface IPasswordEntryProps extends ITextFieldProps {
    label?: string;
}

export const PasswordEntry = memo((props: IPasswordEntryProps) => {
    const { label, ...rest } = props;
    const [isPasswordVisible, setPasswordVisible] = useState(false);

    function handleVisibleToggleClick() {
        setPasswordVisible(!isPasswordVisible);
    }

    return (
        <TextField
            {...rest}
            label={label}
            type={isPasswordVisible ? 'text' : 'password'}
            fullWidth
            InputProps={{
                ...rest.InputProps,
                // startAdornment: (
                //     <InputAdornment position="start">
                //         <Icon icon={faLockAlt} />
                //     </InputAdornment>
                // ),
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleVisibleToggleClick}
                            size="large"
                        >
                            <Icon icon={isPasswordVisible ? faEye : faEyeSlash} />
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    );
});

export default PasswordEntry;
