import { IState } from '../models/state/IState';

export const getAuthorizationState = (state: IState) => state.authorization;

export const getUserState = (state: IState) => state.user;
export const getUser = (state: IState) => getUserState(state).user;

export const getFacilityState = (state: IState) => state.user;
export const getFacility = (state: IState) => getFacilityState(state).facility;

export const isAuthenticated = (state: IState) => Boolean(getAuthorizationState(state).userId);
