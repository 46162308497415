import { faCheckSquare, faMinusSquare, faSquare } from '@fortawesome/pro-light-svg-icons';
import { LabComponentsPropsList } from '@mui/lab/themeAugmentation';
import { ThemeOptions } from '@mui/material/styles';

import Icon from '../components/Icon';

export type _internalThemeOptions = LabComponentsPropsList;

export const DefaultTheme: ThemeOptions = {
    direction: 'ltr',
    //transitions: {},

    /* transitions: {
        create: (props, options?) =>{

        }
    }, */
    palette: {
        background: {
            default: '#F4F5F7',
        },
        primary: {
            light: '#444',
            // main: '#000',
            main: '#000',
            dark: '#111',
            contrastText: '#fff',
        },
        secondary: {
            light: '#f9683a',
            main: '#fff',
            dark: '#870000',
            contrastText: '#fff',
        },
        error: {
            main: '#F44336',
        },
        success: {
            light: '#80e27e',
            main: '#4CAF50',
            dark: '#087f23',
        },
        info: {
            main: '#2C66D0',
        },
        warning: {
            main: '#EDC32B',
        },
        mode: 'light',
    },
    typography: {
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        button: {
            fontWeight: 600,
        },
    },

    components: {
        MuiTextField: {
            defaultProps: {
                variant: 'outlined',
                //  color: 'secondary',
            },
        },
        MuiCheckbox: {
            defaultProps: {
                icon: <Icon icon={faSquare} />,
                checkedIcon: <Icon icon={faCheckSquare} />,
                indeterminateIcon: <Icon icon={faMinusSquare} />,
                // color: 'secondary',
            },
        },
        MuiLink: {
            defaultProps: {
                underline: 'hover',
            },
        },

        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    fontSize: '1.25rem',
                    // // fill: '#000000',
                },
                fontSizeSmall: {
                    fontSize: '1rem',
                },
            },
        },

        MuiPaper: {
            styleOverrides: {
                rounded: {
                    borderRadius: 9,
                },
                elevation18: {
                    boxShadow: '0px 0px 20px 0px rgba(130,130,130,0.75)',
                },
            },
        },

        MuiIconButton: {
            styleOverrides: {
                sizeSmall: {
                    width: 28,
                    height: 28,
                    padding: 6,
                },
            },
        },
        MuiButton: {
            defaultProps: {
                disableElevation: true,
            },
            styleOverrides: {
                root: {
                    minWidth: 'auto',
                },
            },
        },

        MuiListItem: {
            styleOverrides: {
                button: {
                    borderRadius: 4,
                },
            },
        },

        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    minWidth: 40,
                },
            },
        },
    },
};
