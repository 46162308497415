import CoreConfiguration from '@intermedia/core/config';

const Config = {
    BASE_URL: process.env.REACT_APP_BASE_URL || '',
    FALLBACK_IMAGE:
        'https://vimcare.com/assets/empty_user-e28be29d09f6ea715f3916ebebb525103ea068eea8842da42b414206c2523d01.png',
};

CoreConfiguration.BASE_URL = Config.BASE_URL;

export default Config;
