import FetchService from '@intermedia/core/FetchService';
import ApiRoutes from 'config/ApiRoutes';
import { IFacility } from 'models/domain/facility/IFacility';

export class DirectInteractionActionCreator {
    public static async getPageContent(id: string) {
        const href = ApiRoutes.createUrl(ApiRoutes.Page.Self(id));

        const res = await FetchService.get(href);

        if (!res.ok) {
            return null;
        }
        const response = (await res.json()) as IFacility;

        return response;
    }
}
