import './index.scss';

import Bootstrapper from '@intermedia/core/Bootstrapper';
import { IAction, IReducer, PersistGate, StoreProvider } from '@intermedia/core/StoreManager';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { CircularProgress, createTheme, CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { deDE } from '@mui/material/locale';
import deLocale from 'date-fns/locale/de';
import React, { memo, Suspense, useContext, useMemo } from 'react';
import { BrowserRouter, useRoutes } from 'react-router-dom';

import { AuthenticationAction, AuthenticationActions } from './actions/AuthenticationActionCreator';
import { AppRouteConfig } from './config/routes/AppRouteConfig';
import AppMiddlewares from './middlewares/AppMiddlewares';
import { IState } from './models/state/IState';
import AppReducer from './reducer/AppReducer';
import ResourceContextProvider from './services/i18n/context/ResourceContextProvider';
import NotificationProvider from './services/Notifications/context/NotificationProvider';
import { ThemeContext } from './theme/ThemeContext';
import { ThemeContextProvider } from './theme/ThemeContextProvider';

// import MainView from './views/MainView/MainView';

const combinedReducer = AppReducer;

const rootReducer: IReducer<IState> = (state: IState, action: AuthenticationAction | IAction) => {
    let newState: IState | undefined = state;
    if (action.type === AuthenticationActions.SUBMIT_LOGOUT_RESPONSE) {
        newState = undefined;
    }
    return combinedReducer(newState, action);
};

const { store, persistor } = Bootstrapper.initializeStore(rootReducer, AppMiddlewares);

const App = memo((props: unknown) => {
    const { theme } = useContext(ThemeContext);
    const muiTheme = useMemo(() => createTheme(theme, deDE), [theme]);

    const baseRoutes = useRoutes(AppRouteConfig);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={muiTheme}>
                <CssBaseline />
                <NotificationProvider>
                    <Suspense
                        fallback={
                            <div className="flex centered">
                                <CircularProgress color="secondary" />
                            </div>
                        }
                    >
                        {baseRoutes}
                    </Suspense>
                </NotificationProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
});

const AppContainer = () => {
    return (
        <StoreProvider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <ResourceContextProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={deLocale}>
                        <ThemeContextProvider>
                            <BrowserRouter>
                                <App />
                            </BrowserRouter>
                        </ThemeContextProvider>
                    </LocalizationProvider>
                </ResourceContextProvider>
            </PersistGate>
        </StoreProvider>
    );
};

class ErrorBoundary extends React.Component<{}, { didError: boolean }> {
    constructor(props: {}) {
        super(props);
        this.state = { didError: false };
    }

    // eslint-disable-next-line class-methods-use-this
    public render() {
        return <AppContainer />;
    }

    // eslint-disable-next-line class-methods-use-this
    public componentDidCatch() {
        localStorage.clear();
        this.setState({ didError: true });
    }
}

export default ErrorBoundary;
