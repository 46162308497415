export interface IFacilityStatisticsResponse {
    pageViewsTotal: number;
    pageViewDrawback: ILastWeekStatistic[];
}

export interface ILastWeekStatistic {
    date: Date;
    value: number;
}

export const defaultLastWeekStatistic: ILastWeekStatistic = {
    date: new Date(),
    value: 0,
};

export const dafaultFacilityStatisticsResponse: IFacilityStatisticsResponse = {
    pageViewsTotal: 0,
    pageViewDrawback: [defaultLastWeekStatistic],
};
