import { useEffect } from 'react';

import { IBreadCrumb } from '../models/IBreadCrumb';
import useBreadcrumbs from './useBreadcrumbs';

export function useBreadcrumb(breadCrumb: IBreadCrumb[], mode: 'add' | 'set' = 'set') {
    const { setBreadcrumbs, addBreadcrumb, removeBreadcrumb } = useBreadcrumbs();

    useEffect(() => {
        if (mode === 'set') {
            setBreadcrumbs(breadCrumb);
        } else {
            addBreadcrumb(breadCrumb);
        }

        return () => {
            if (mode === 'set') {
                setBreadcrumbs([]);
            } else {
                removeBreadcrumb(breadCrumb);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setBreadcrumbs, breadCrumb.length]);
}

export default useBreadcrumb;
