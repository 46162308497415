import { useCallback, useEffect, useMemo, useState } from 'react';

import { ReactChildren } from '../../../models/core/ReactChildren';
import { Language, Languages } from '../models/ILanguageDictionary';
import ResourceService from '../ResourcesService';
import ResourceContext, { IResourceContext } from './ResourceContext';

export interface IResourceContextProviderProps {
    children?: ReactChildren;
}

export const ResourceContextProvider = (props: IResourceContextProviderProps) => {
    const { children } = props;

    //const user = useSelector(getUser);

    //const { setValue } = useLocalstorage();

    const initLang = useMemo(
        // () => (value ? (value as Language) : navigator.language === 'en' ? Languages.EN : Languages.DE),
        () => Languages.DE,
        []
    );

    const [lang, setLanguage] = useState<Language>(initLang);

    useEffect(() => {
        setLanguage(initLang);
    }, [initLang]);

    const handleLanguageChange = useCallback((newLanguage: Language) => {
        //setValue(newLanguage);
        setLanguage(newLanguage);
    }, []);

    const ctx = useMemo<IResourceContext>(() => {
        ResourceService.changeCurrentLanguage(lang);
        return {
            resources: ResourceService.getCurrentResources(),
            currentLanguage: lang,
            changeLanguage: handleLanguageChange,
        };
    }, [handleLanguageChange, lang]);

    return <ResourceContext.Provider value={ctx}>{children}</ResourceContext.Provider>;
};

export default ResourceContextProvider;
