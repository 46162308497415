import FetchService from '@intermedia/core/FetchService';
import { IEntityCollection } from '@intermedia/core/models/core/entity';
import { ActionCreator, createAsyncActionEnum, IAsyncAction } from '@intermedia/core/StoreManager';

import ApiRoutes from '../config/ApiRoutes';
import { IUser } from '../models/domain/identity';
import { DeclareAsyncAction } from './../models/state/AsyncActionLiteralCreatorType';

export const UserActions = {
    ...createAsyncActionEnum('GET_USERS'),
    ...createAsyncActionEnum('GET_USER'),
    ...createAsyncActionEnum('DELETE_USER'),
    // ...createAsyncActionEnum('GET_TRANSACTIONS_OF_USER'),
    // ...createAsyncActionEnum('GET_COIN_TRANSACTIONS_OF_USER'),

    // ...createAsyncActionEnum('GET_DIALOGS_OF_USER'),
    // ...createAsyncActionEnum('GET_INTERACTIONS_OF_USER'),
    // ...createAsyncActionEnum('GET_DIALOG'),
    // ...createAsyncActionEnum('GET_ALL_MESSAGES_OF_USER'),
    // ...createAsyncActionEnum('ADD_AGENCY_USER'),
    // ...createAsyncActionEnum('UPDATE_AGENCY_USER'),
    // ...createAsyncActionEnum('DELETE_AGENCY_USER'),
} as const;

export type UserAction =
    | DeclareAsyncAction<typeof UserActions.GET_USERS, IEntityCollection<IUser>>
    | DeclareAsyncAction<typeof UserActions.GET_USER, IUser>
    | DeclareAsyncAction<typeof UserActions.DELETE_USER, undefined, string>;

export class UserActionCreator {
    public static getUsers(): IAsyncAction {
        const href = ApiRoutes.createUrl(ApiRoutes.Administration.User.Base);

        return ActionCreator.createAsyncAction(
            UserActions.GET_USERS,
            [UserActions.GET_USERS_REQUEST, UserActions.GET_USERS_RESPONSE, UserActions.GET_USERS_FAILED],
            () => FetchService.get(href)
        );
    }

    public static getUser(id: string): IAsyncAction {
        const href = ApiRoutes.createUrl(ApiRoutes.User.Self(id));

        return ActionCreator.createAsyncAction(
            UserActions.GET_USER,
            [UserActions.GET_USER_REQUEST, UserActions.GET_USER_RESPONSE, UserActions.GET_USER_FAILED],
            () => FetchService.get(href)
        );
    }

    public static deleteUser(id: string): IAsyncAction {
        const href = ApiRoutes.createUrl(ApiRoutes.User.Self(id));

        return ActionCreator.createAsyncAction(
            UserActions.DELETE_USER,
            [UserActions.DELETE_USER_REQUEST, UserActions.DELETE_USER_RESPONSE, UserActions.DELETE_USER_FAILED],
            () => FetchService.delete(href)
        );
    }
}

export default UserActionCreator;
