import { getResponse, updateState } from '@intermedia/core/StoreManager';
import { AuthenticationAction, AuthenticationActions } from '../actions/AuthenticationActionCreator';

export interface IAuthenticationState {
    userId: string | undefined;
}

const AuthorizationDefault: IAuthenticationState = {
    userId: undefined,
};

export function AuthenticationReducer(
    state: IAuthenticationState = AuthorizationDefault,
    action: AuthenticationAction
): IAuthenticationState {
    switch (action.type) {
        case AuthenticationActions.SESSION_EXPIRED:
        case AuthenticationActions.SUBMIT_LOGOUT_RESPONSE:
        case AuthenticationActions.SUBMIT_LOGOUT_FAILED: {
            return AuthorizationDefault;
        }
        case AuthenticationActions.SUBMIT_LOGIN_RESPONSE: {
            const user = getResponse(action);

            return updateState(state, {
                userId: user?.id,
            });
        }

        default:
            return state;
    }
}
export default AuthenticationReducer;
