import FetchService from '@intermedia/core/FetchService';
import { ActionCreator, createAsyncActionEnum, DeclareAsyncAction } from '@intermedia/core/StoreManager';

import ApiRoutes from '../config/ApiRoutes';

export const DashboardActions = {
    ...createAsyncActionEnum('GET_DASHBOARD'),
} as const;

export type DashboardAction = DeclareAsyncAction<'GET_DASHBOARD'>;

export class DashboardActionCreator {
    public static getDashboard() {
        return ActionCreator.createAsyncActionByType('GET_DASHBOARD', () => FetchService.get(ApiRoutes.Administration.Dashboard.Base));
    }
}

export default DashboardActionCreator;
