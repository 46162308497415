import './BreadCrumbs.scss';

import { faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { useSelector } from '@intermedia/core/StoreManager';
import { Breadcrumbs } from '@mui/material';
import { memo } from 'react';

import Icon from '../../../../components/Icon';
import { IState } from '../../../../models/state/IState';
import BreadCrumbElement from './BreadCrumbElement';

// icon width would be 24, yet we have some offset
export const ICON_WIDTH = 40;
export const CHAR_WIDTH = 15;

export interface IBreadCrumbsProps {}

export const BreadCrumbs = memo((props: IBreadCrumbsProps) => {
    const breadCrumbs = useSelector((state: IState) => state.breadCrumbs.breadCrumbs);

    const max = breadCrumbs.length;

    return (
        <div id="breadcrumbs-navbar" className="breadcrumb flex row">
            <Breadcrumbs
                separator={
                    <Icon icon={faChevronRight} fontSize="small" style={{ width: 16, height: 13, fontSize: 12 }} />
                }
            >
                {breadCrumbs.map((breadCrumb, i) => (
                    <BreadCrumbElement breadCrumb={breadCrumb} key={i} isActive={Number(i) + 1 === max} />
                ))}
            </Breadcrumbs>
        </div>
    );
});

export default BreadCrumbs;
