import { ChangeEvent, FormEvent, memo, useCallback, useState } from 'react';
import { AuthenticationAction, AuthenticationActions } from '../../../actions/AuthenticationActionCreator';
import Checkbox from '../../../components/Checkbox';
import LoadingButton from '../../../components/LoadingButton';
import PasswordEntry from '../../../components/PasswordEntry';
import TextField from '../../../components/TextField';

export interface ILoginFormProps {
    onSubmitClick(username: string, password: string, rememberlogin: boolean): Promise<AuthenticationAction>;
}

export const LoginForm = memo((props: ILoginFormProps) => {
    const { onSubmitClick } = props;

    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const [rememberLogin, setRememberLogin] = useState<boolean>(true);
    const [usernameErrorText, setUsernameErrorText] = useState<string | undefined>(undefined);
    const [passwordErrorText, setPasswordErrorText] = useState<string | undefined>(undefined);

    const [isLoading, setLoading] = useState<boolean>(false);

    const handleSubmit = useCallback(
        async (event: FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            if (username.length === 0) {
                setUsernameErrorText('Bitte einen Benutzername eingeben!');
                return;
            }

            if (password.length === 0) {
                setPasswordErrorText('Bitte ein Passwort eingeben!');
                return;
            }

            setLoading(true);
            const res = await onSubmitClick(username, password, rememberLogin);
            setLoading(false);

            if (res) {
                if (res.type === AuthenticationActions.SUBMIT_LOGIN_FAILED) {
                    setUsernameErrorText('Benutzername oder Passwort falsch!');
                    // case AuthenticationStates.FAILED: {
                    //     setUsernameErrorText('Benutzername oder Passwort falsch!');
                    //     break;
                    // }
                    // case AuthenticationStates.UNKNOWN_ACCOUNT: {
                    //     setUsernameErrorText('Dieser Account existiert nicht.');
                    //     break;
                    // }
                    // default: {
                    //     setUsernameErrorText('');
                    //     setPasswordErrorText('');
                    // }
                }
            }
        },
        [username, onSubmitClick, password, rememberLogin]
    );

    const handlePasswordChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setPassword(event.currentTarget.value);
        setPasswordErrorText(undefined);
    }, []);

    const handleusernameChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setUsername(event.currentTarget.value);
        setUsernameErrorText(undefined);
    }, []);

    const handleRememberClick = useCallback(() => setRememberLogin(!rememberLogin), [rememberLogin]);

    return (
        <form
            id="login-form"
            className="flex spacing padding top bottom justify-content-center"
            onSubmit={handleSubmit}
        >
            <TextField
                className="spacing margin bottom double"
                id="username-input"
                label="Benutzername"
                aria-label="username"
                required
                autoFocus
                defaultValue={username}
                onChange={handleusernameChange}
                helperText={usernameErrorText}
                error={Boolean(usernameErrorText)}
            />

            <PasswordEntry
                className="spacing margin bottom double"
                id="password-input"
                aria-label="Password"
                label="Passwort"
                defaultValue={password}
                onChange={handlePasswordChange}
                helperText={passwordErrorText}
                error={Boolean(passwordErrorText)}
                required
            />

            <Checkbox
                checked={rememberLogin}
                onClick={handleRememberClick}
                label="Angemeldet bleiben"
                labelClassName="login-form-checkbox spacing margin top bottom"
                labelPlacement="end"
            />
            <div className="flex no-grow align-items-center">
                <LoadingButton
                    aria-label="login-submit"
                    className="spacing margin top bottom"
                    isLoading={isLoading}
                    type="submit"
                    variant="contained"
                >
                    Anmelden
                </LoadingButton>
            </div>
        </form>
    );
});

export default LoginForm;
