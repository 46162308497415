import Config from './Config';

const Root = '/v1';

const IdentityBase = `${Root}/identity`;

const UserBase = `${Root}/user`;
// const TableBase = `${Root}/table`;

const AdministrationRoot = `${Root}/administration`;

const routeBases = {
    User: `${Root}/user`,
    Restaurant: `${Root}/restaurant`,
    Page: `${Root}/page`,
    Administration: {
        User: `${AdministrationRoot}/user`,
        Dashboard: `${AdministrationRoot}/dashboard`,
        Facility: `${AdministrationRoot}/facility`,
    },
} as const;

class ApiRoutes {
    public static Base = Root;

    public static Identity = class {
        public static Base = IdentityBase;
        public static Register = IdentityBase + '/register';
        public static RegisterWithApple = IdentityBase + '/register/apple';
        public static RegisterWithFacebook = IdentityBase + '/register/facebook';
        public static RegisterWithGoogle = IdentityBase + '/register/google';

        public static Login = IdentityBase;
        public static LoginWithApple = IdentityBase + '/login/apple';
        public static LoginWithFacebook = IdentityBase + '/login/facebook';
        public static LoginWithGoogle = IdentityBase + '/login/google';
        public static Logout = IdentityBase + '/logout';
        public static ResetPassword = IdentityBase + '/resetPassword';

        public static ChangePassword = IdentityBase + '/password';
        public static ConfirmResetPassword = (userId: string) => ApiRoutes.Identity.ResetPassword + '/' + userId;
        public static VerifyEmail = (userId: string) => IdentityBase + `/verify/${userId}/token`;
        public static ResendVerificationEmail = (userId: string) => IdentityBase + `/verify/${userId}/resend`;
    };

    public static User = class {
        public static Base = routeBases.User;
        public static Image = `${UserBase}/image`;
        public static Self = (id: string) => `${ApiRoutes.User.Base}/${id}`;
        public static ImageSelf = (id: string) => `${ApiRoutes.User.Image}/${id}`;
    };

    public static Page = class {
        public static Base = routeBases.Page;
        public static Self = (id: string) => `${ApiRoutes.Page.Base}/${id}`;
    };

    public static Administration = class {
        public static AdministrationRoot = AdministrationRoot;

        public static Dashboard = class {
            public static Base = routeBases.Administration.Dashboard;
            public static Orders = `${routeBases.Administration.Dashboard}/order`;
            public static Payment = `${routeBases.Administration.Dashboard}/payment`;
            public static Reservation = `${routeBases.Administration.Dashboard}/reservation`;
        };

        public static User = class {
            public static Base = routeBases.Administration.User;
            public static Self = (id: string) => `${ApiRoutes.Administration.User.Base}/${id}`;
        };

        public static Facility = class {
            public static Base = routeBases.Administration.Facility;
            public static Self = (id: string) => `${ApiRoutes.Administration.Facility.Base}/${id}`;
            public static Statistics = (id: string) => `${ApiRoutes.Administration.Facility.Self(id)}/statistics`;
        };
    };

    public static createUrl(uri: string, ...searchParams: Array<[string, string]>): string {
        const url = new URL(uri, Config.BASE_URL);

        if (searchParams) {
            for (const [name, value] of searchParams) {
                url.searchParams.append(name, value);
            }
        }

        return url.toString();
    }
}

export default ApiRoutes;
