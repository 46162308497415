import HistoryManager from '@intermedia/core/HistoryManager';
import { IDispatch, IMiddlewareAPI, isResponseAction, PersistorAction } from '@intermedia/core/StoreManager';
import { AuthenticationAction, AuthenticationActionCreator } from '../actions/AuthenticationActionCreator';
import { IState } from '../models/state/IState';
import { AuthenticationActions } from './../actions/AuthenticationActionCreator';
import { MAIN_PATH } from './../config/Paths';

export function AuthorizationMiddleware(middlewareAPI: IMiddlewareAPI<IState>) {
    return (next: IDispatch) => async (action: AuthenticationAction | PersistorAction) => {
        const { dispatch } = middlewareAPI;
        if (isResponseAction(action)) {
            if (action.payload.response?.redirected && action.payload.response?.status === 404) {
                await next(action);

                dispatch(AuthenticationActionCreator.sessionExpired());
            }
        }

        switch (action.type) {
            case AuthenticationActions.SUBMIT_LOGIN_RESPONSE: {
                await next(action);

                if (action.payload.result) {
                    HistoryManager.push(MAIN_PATH);
                }

                break;
            }
            /*
            case AuthenticationActions.RESET_PASSWORD_RESPONSE: {
                await next(action);
                HistoryManager.push(LOGIN_PATH);
                break;
            }  */
            default:
                return await next(action);
        }
    };
}

export default AuthorizationMiddleware;
