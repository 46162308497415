/* eslint-disable @typescript-eslint/naming-convention */

export const MAIN_PATH = '/';

// Login & register
export const LOGIN_PATH = '/login';
export const REGISTER_PATH = '/register';
export const RESET_PASSWORD_PATH = '/resetPassword';
export const CONFIRM_EMAIL_PATH = '/confirmEmail';

// Restaurant
export const RESTAURANT_PATH = '/restaurant';

// Redirect
export const REDIRECT_PATH = '/redirect';

// Dish
export const DISH_PATH = '/dish';

// DishType
export const DISH_TYPE_PATH = '/dishType';

// Bundle
export const BUNDLE_PATH = '/bundle';
export const BUNDLE_ITEM_PATH = BUNDLE_PATH + '/item';

// Allergen
export const ALLERGEN_PATH = '/allergen';

// Category
export const CATEGORY_PATH = '/category';

// Order
export const ORDER_PATH = '/order';

// Payment
export const PAYMENT_PATH = '/payment';

// Reservation
export const RESERVATION_PATH = '/reservation';

// Table
export const TABLE_PATH = '/table';

//Own-Profile
export const OWN_PROFILE_PATH = '/profile';

// AGB & Datenscutz
export const AGB_PATH = '/agb';
export const DATENSCHUTZ_PATH = '/datenschutz';
