import { Typography } from '@mui/material';

export const DataSafety = () => {
    return (
        <div className="flex align-items-center">
            <div className="flex align-items-center spacing padding all triple" style={{ maxWidth: 800, width: '100%' }}>
                <Typography>Datenschutz</Typography>
            </div>
        </div>
    );
};

