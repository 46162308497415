import { RouteObject } from 'react-router';
import { FacilityWrapper } from 'views/Facility/FacilityWrapper';
import { MainLanding } from 'views/Landingpages/MainLanding/MainLanding';

import SecuredRoute from '../../components/security/SecuredRoute';
import LoginView from '../../views/authentication/LoginView';
import MainView from '../../views/MainView/MainView';

export const AppRouteConfig: RouteObject[] = [
    {
        path: '/',
        element: (
            <SecuredRoute insecureOnly>
                <MainLanding />
            </SecuredRoute>
        ),
    },
    {
        path: 'login',
        element: (
            <SecuredRoute insecureOnly>
                <LoginView />
            </SecuredRoute>
        ),
    },
    {
        path: '/p/:id',
        element: (
            <SecuredRoute insecureOnly>
                <FacilityWrapper />
            </SecuredRoute>
        ),
    },
    {
        path: '/*',
        element: (
            <SecuredRoute>
                <MainView />
            </SecuredRoute>
        ),
    },
];
