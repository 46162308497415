import './Dashboard.scss';

import IIFE from '@intermedia/core/common/helper/IIFE';
import { useDispatch, useSelector } from '@intermedia/core/StoreManager';
import FacilityActionCreator from 'actions/FacilityActionCreator';
import UserActionCreator from 'actions/UserActionCreator';
import { useEffect, useState } from 'react';
import { getClickStatistics, getUser } from 'selectors';

import DashboardActionCreator from '../../actions/Dashboard.actioncreator';
import useBreadcrumb from '../../services/BreadCrumbs/core/useBreadcrumb';
import DashboardColumn, { IDashboardColumn } from './components/DashboardColumn';
import { WeekDrawbackGraph } from './components/WeekDrawbackGraph/WeekDrawbackGraph';

export interface IDashboardProps {}

export const Dashboard = (props: IDashboardProps) => {
    // useBreadcrumb([{ text: 'Dashboard' }]);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const dispatch = useDispatch();
    useBreadcrumb([{ text: 'Dashboard' }]);

    const user = useSelector(getUser);

    useEffect(() => {
        IIFE(async () => {
            setIsLoading(true);
            await dispatch(DashboardActionCreator.getDashboard());
            await dispatch(UserActionCreator.getUsers());
            setIsLoading(false);
        });
    }, [dispatch]);

    useEffect(() => {
        IIFE(async () => {
            setIsLoading(true);
            await dispatch(FacilityActionCreator.getFacilityStatistics(user?.facilityId ?? ''));
            setIsLoading(false);
        });
    }, [dispatch, user]);

    const clickStatistics = useSelector(getClickStatistics);
    const customerValues: IDashboardColumn[] = [
        {
            title: 'Klicks gesamt',
            values: [clickStatistics.pageViewsTotal.toString()],
        },
    ];

    return (
        <section className="flex dashboard">
            <DashboardColumn isLoading={isLoading} columnTitle="Leads" tiles={customerValues} />
            {/* <DashboardColumn isLoading={isLoading} columnTitle="Umsätze" tiles={salesValues} /> */}

            {/* <OrderView /> */}
            {/* <Chart options={chartOptions} series={chartSeries} type="bar" width="500" /> */}
            <WeekDrawbackGraph data={clickStatistics} />
        </section>
    );
};

export default Dashboard;

// const formatPrice = (value: number = 0) =>
//     value.toLocaleString('de', {
//         style: 'currency',
//         currency: 'EUR',
//     });
