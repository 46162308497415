import MuiCheckbox, { CheckboxProps } from '@mui/material/Checkbox';
import FormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel';

export interface ICheckboxProps extends CheckboxProps {
    label?: FormControlLabelProps['label'];
    labelClassName?: string;
    labelPlacement?: FormControlLabelProps['labelPlacement'];
}

export const Checkbox = (props: ICheckboxProps) => {
    const { label, labelClassName, labelPlacement, ...rest } = props;

    const main = <MuiCheckbox {...rest} />;

    if (label) {
        return (
            <FormControlLabel control={main} label={label} className={labelClassName} labelPlacement={labelPlacement} />
        );
    }

    return main;
};

export default Checkbox;
