import { Button, Dialog, Typography } from '@mui/material';

interface IWheelDialogProps {
    isOpen: boolean;
    link: string;
    onClose(): void;
}

export const WhatsappDialog = (props: IWheelDialogProps) => {
    const { isOpen = false, link, onClose } = props;

    const handleClickButton = () => {
        //TODO: open Whatsapp
        window.open(link, '_self');
    };

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <Typography>Hole dir jetzt deinen Gutscheincode!</Typography>
            <Button onClick={handleClickButton}>Jetzt Gutschein erhalten</Button>
        </Dialog>
    );
};
