import { Toolbar } from '@mui/material';
import { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';

import BreadCrumbs from '../../../services/BreadCrumbs/breadcrumbs';
import HeaderUser from './HeaderUser';

interface IHeaderProps extends MuiAppBarProps {
    open?: boolean;
    handleDrawerOpen(): void;
}

export const Header = (props: IHeaderProps) => {
    return (
        <Toolbar className="flex row no-grow justify-content-space-between">
            <BreadCrumbs />

            <HeaderUser />
        </Toolbar>
    );
};

export default Header;
