import { IPrice } from 'models/domain/price/IPrice';

export const generateWhatsappLink = (prize?: IPrice, facilityName?: string) => {
    if (!prize || !facilityName) {
        return '';
    }
    const baseUrl = 'https://api.whatsapp.com/send/?phone=14434894490';

    const text = `&text=Hallo ${facilityName}, Ich habe gerade '${prize.name}' gewonnen!`;

    return baseUrl + text;
};
