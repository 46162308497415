import { useDispatch } from '@intermedia/core/StoreManager';
import { useCallback } from 'react';

import { BreadCrumbActionCreator } from '../actions/BreadCrumbActionCreator';
import { IBreadCrumb } from '../models/IBreadCrumb';

export interface IUseBreadcrumbs {
    setBreadcrumbs(breadCrumbs: IBreadCrumb[]): void;
    addBreadcrumb(breadCrumb: IBreadCrumb | IBreadCrumb[]): void;
    removeBreadcrumb(breadCrumb: IBreadCrumb | IBreadCrumb[]): void;
    resetBreadcrumbs(): void;
}

export function useBreadcrumbs(): IUseBreadcrumbs {
    const dispatch = useDispatch();

    const setBreadcrumbs = useCallback(
        (breadCrumbs: IBreadCrumb[]) => dispatch(BreadCrumbActionCreator.setBreadcrumbs(breadCrumbs)),
        [dispatch]
    );
    const addBreadcrumb = useCallback(
        (breadCrumb: IBreadCrumb | IBreadCrumb[]) => dispatch(BreadCrumbActionCreator.addBreadcrumb(breadCrumb)),
        [dispatch]
    );
    const removeBreadcrumb = useCallback(
        (breadCrumb: IBreadCrumb | IBreadCrumb[]) => dispatch(BreadCrumbActionCreator.removeBreadcrumb(breadCrumb)),
        [dispatch]
    );
    const resetBreadcrumbs = useCallback(() => dispatch(BreadCrumbActionCreator.resetBreadcrumbs()), [dispatch]);

    return {
        setBreadcrumbs: setBreadcrumbs,
        addBreadcrumb: addBreadcrumb,
        removeBreadcrumb: removeBreadcrumb,
        resetBreadcrumbs: resetBreadcrumbs,
    };
}

export default useBreadcrumbs;
export * from '../models/IBreadCrumb';
export * from '../models/IBreadCrumbRendererProps';
