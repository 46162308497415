import { useSelector } from '@intermedia/core/StoreManager';
import { memo, ReactElement } from 'react';
import { Navigate } from 'react-router-dom';

import { LOGIN_PATH, MAIN_PATH } from '../../config/Paths';
import { isAuthenticated } from '../../selectors/AuthorizationSelectors';

export interface ISecuredRouteProps {
    fallback?: string;
    insecureOnly?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children: ReactElement<any, any>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SecuredRoute = memo((props: ISecuredRouteProps): ReactElement<any, any> => {
    const { fallback = LOGIN_PATH, insecureOnly, children } = props;
    const authenticated = useSelector(isAuthenticated);

    if (!authenticated) {
        if (!insecureOnly) {
            return <Navigate to={fallback} />;
        }
    } else {
        if (insecureOnly) {
            return <Navigate to={MAIN_PATH} />;
        }
    }

    return <>{children}</>;
});

export default SecuredRoute;
