import { faCheckCircle, faExclamationTriangle, faInfoCircle, faTimes } from '@fortawesome/pro-light-svg-icons';
import { SnackbarOrigin } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import Icon from '../../../components/Icon';
import Notifier from '../Notifier';

export interface INotificationProviderProps {
    children?: React.ReactNode;
}

const snackBarOrigin: SnackbarOrigin = {
    vertical: 'top',
    horizontal: 'right',
};

const iconVariant = {
    success: <Icon icon={faCheckCircle} className="spacing margin right" />,
    error: <Icon icon={faTimes} className="spacing margin right" />,
    warning: <Icon icon={faExclamationTriangle} className="spacing margin right" />,
    info: <Icon icon={faInfoCircle} className="spacing margin right" />,
};

export const NotificationProvider = (props: INotificationProviderProps) => {
    const { children } = props;

    return (
        <SnackbarProvider anchorOrigin={snackBarOrigin} iconVariant={iconVariant} maxSnack={4}>
            <Notifier />
            {children}
        </SnackbarProvider>
    );
};

export default NotificationProvider;
