import { ThemeOptions } from '@mui/material/styles';

import { DefaultTheme } from './DefaultTheme';

export const DarkTheme: ThemeOptions = {
    ...DefaultTheme,
    palette: {
        ...DefaultTheme.palette,
        primary: {
            // light: '#3e3e3e',
            // main: '#181818',
            // dark: '#000000',
            light: '#fff,',
            main: '#F4F5F7',
            dark: '#e7e7e7',
            contrastText: '#000',
        },
        background: {
            default: '#2c2c2e',
        },
        // secondary: {
        //     light: '#fff,',
        //     main: '#F4F5F7',
        //     dark: '#e7e7e7',
        //     contrastText: '#000',
        // },
        mode: 'dark',
    },
    typography: {
        ...DefaultTheme.typography,
        allVariants: {
            color: '#fff',
        },
    },

    components: {
        ...DefaultTheme.components,
        MuiAppBar: {
            styleOverrides: {
                colorPrimary: {
                    backgroundColor: '#424242',
                },
            },
        },
    },
};
