import { ReactChildren } from '@intermedia/core/models/helper/ReactChildren';
import { Button, Divider, Toolbar, Typography } from '@mui/material';

import DashboardTile from './DashboardTile';

export interface IDashboardColumn {
    title: string;
    values: string[];
    divider?: boolean;
    onClick?(): void;
}

export interface IDashboardColumnProps {
    columnTitle: string;
    tiles?: IDashboardColumn[];
    buttonText?: string;
    isLoading?: boolean;
    children?: ReactChildren;
    onClick?(): void;
}

export const DashboardColumn = (props: IDashboardColumnProps) => {
    const { columnTitle, tiles, onClick, isLoading, buttonText = 'Mehr Ansehen', children } = props;

    return (
        <div className="flex spacing margin right left">
            <Toolbar disableGutters className="flex no-grow row">
                <Typography variant="h5">{columnTitle}</Typography>
                {onClick && (
                    <Button
                        onClick={onClick}
                        color="secondary"
                        variant="outlined"
                        className="spacing margin left double"
                    >
                        {buttonText}
                    </Button>
                )}
            </Toolbar>
            <Divider className="spacing margin double bottom" />
            <div className="flex">
                {children}
                {tiles?.map((tile) => (
                    <>
                        <DashboardTile
                            key={tile.title}
                            isLoading={isLoading}
                            title={tile.title}
                            values={tile.values}
                            onClick={tile.onClick}
                        />
                        {tile.divider && <Divider className="spacing margin double bottom" />}
                    </>
                ))}
            </div>
        </div>
    );
};

export default DashboardColumn;
