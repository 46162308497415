import { useState } from 'react';
import { SketchPicker } from 'react-color';

interface IColorPickerComponentProps {
    color: string;
    onChange(e: string): void;
}

export const ColorPickerComponent = (props: IColorPickerComponentProps) => {
    const { color, onChange } = props;
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);

    const handleToggleDialog = () => {
        setDialogOpen(!dialogOpen);
    };

    return (
        <div>
            <div className="flex row cursor pointer" style={{ position: 'relative' }} onClick={handleToggleDialog}>
                <div className="spacing margin padding all">Primärfarbe</div>
                <div style={{ border: '1px solid lightgrey' }} className="flex row align-items-center no-grow spacing margin padding all">
                    <div className="spacing padding double" style={{ backgroundColor: color, width: 20, height: 20, borderRadius: 100 }} />
                    <div className="spacing margin left double">{color}</div>
                </div>
                <div></div>
                <div style={{ position: 'absolute', top: 40, left: 30 }} onClick={() => null}>
                    {dialogOpen && <SketchPicker color={color} onChange={(e) => onChange(e.hex)} />}
                </div>
            </div>
        </div>
    );
};
