import { AsyncActionEnumCreator, AsyncActionEnumValueCreator } from './AsyncActionLiteralCreatorType';

type createAsyncActionEnumResult<T extends string, P extends string = ''> = {
    [k in AsyncActionEnumCreator<T>]: Readonly<AsyncActionEnumValueCreator<k, P>>;
};

export function createAsyncActionEnum<T extends string, P extends string = ''>(
    type: T,
    prefix?: P
): createAsyncActionEnumResult<T, P> {
    const value = (prefix ? `${prefix}.${type}` : type).toUpperCase() as Uppercase<T>;
    return {
        [type]: value,
        [`${type}_REQUEST`]: `${value}_REQUEST`,
        [`${type}_RESPONSE`]: `${value}_RESPONSE`,
        [`${type}_FAILED`]: `${value}_FAILED`,
    } as {
        [k in AsyncActionEnumCreator<T>]: AsyncActionEnumValueCreator<k, P>;
    };
}
