/* eslint-disable max-len */
import { Helmet } from 'react-helmet';


export const MainLandingHelmet = () => {
    return (
        <Helmet>
            <noscript>{`
                <a
                    href="https://www.provenexpert.com/de-de/intermedia-gmbh?utm_source=seals&utm_campaign=proseal&utm_medium=profile&utm_content=eefdf009-f21f-47aa-bcfe-492da63f914a"
                    target="_blank"
                    title="Kundenbewertungen & Erfahrungen zu InterMedia GmbH. "
                    class="pe-pro-seal-more-infos"
                    rel="nofollow noreferrer"
                >
                    Mehr Infos
                </a>
                `}</noscript>
            <script src="https://s.provenexpert.net/seals/proseal.js"></script>
            <script id="proSeal">{`
      window.addEventListener('load', function(event) {
          window.provenExpert.proSeal({
            widgetId: "eefdf009-f21f-47aa-bcfe-492da63f914a",
            language:"de-DE",
            bannerColor: "#2fa155",
            textColor: "#FFFFFF",
            showReviews: true,
            hideDate: true,
            hideName: false,
            hideOnMobile: false,
            bottom: "250px",
            stickyToSide: "right",
            googleStars: false,
            zIndex: "9999",
          })
      });
      `}</script>
        </Helmet>
    );
};
