import { getResponse, updateState } from '@intermedia/core/StoreManager';
import { FacilityAction, FacilityActions } from 'actions/FacilityActionCreator';
import {
    dafaultFacilityStatisticsResponse,
    IFacilityStatisticsResponse,
} from 'models/contracts/responses/Statistics/FacilityStatsticsResponse';

import { DashboardAction, DashboardActions } from '../actions/Dashboard.actioncreator';

export interface IDashboardState {
    dashboard: undefined;
    clicks: IFacilityStatisticsResponse;
}

const DashboardDefault: IDashboardState = {
    dashboard: undefined,
    clicks: dafaultFacilityStatisticsResponse,
};

export function DashboardReducer(state: IDashboardState = DashboardDefault, action: DashboardAction | FacilityAction): IDashboardState {
    switch (action.type) {
        case DashboardActions.GET_DASHBOARD_RESPONSE: {
            return updateState(state, {
                dashboard: getResponse(action),
            });
        }
        case FacilityActions.GET_FACILITY_STATS_RESPONSE: {
            return updateState(state, {
                clicks: getResponse(action),
            });
        }

        default:
            return state;
    }
}
export default DashboardReducer;
