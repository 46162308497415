import HistoryManager from '@intermedia/core/HistoryManager';
import Typography from '@mui/material/Typography';
import { memo, useCallback } from 'react';

import { IBreadCrumb } from '../../models/IBreadCrumb';

export interface IBreadCrumbElementProps {
    breadCrumb: IBreadCrumb;

    isActive?: boolean;
}

export const BreadCrumbElement = memo((props: IBreadCrumbElementProps) => {
    const { breadCrumb, isActive } = props;
    const { href, renderer: Renderer, text, closeIcon, icon, order } = breadCrumb;

    const handleBreadCrumbClick = useCallback(() => {
        if (href) {
            HistoryManager.push(href);
        }
    }, [href]);

    const className = `breadcrumb-item ${isActive ? 'active' : 'inactive'} ${href ? 'pointer' : 'user-select-none'}`;

    if (Renderer) {
        return <Renderer {...props} onBreadCrumbClick={handleBreadCrumbClick} />;
    }

    // {
    //     /* <Typography color="textPrimary">Test</Typography>
    //                 <Typography>Test</Typography>
    //                 <Typography >Test</Typography> */
    // }

    return (
        <Typography variant="h5" className={className} onClick={handleBreadCrumbClick} style={{ order: order }}>
            {icon} {text} {closeIcon}
        </Typography>
    );
});

export default BreadCrumbElement;
