import { IEntityCollection } from '@intermedia/core/models/core/entity';
import { Button, Dialog } from '@mui/material';
import { IPrice } from 'models/domain/price/IPrice';
import { useState } from 'react';
import { Wheel } from 'react-custom-roulette';

interface IWheelDialogProps {
    isOpen: boolean;
    color: string;

    prices?: IEntityCollection<IPrice>;
    onSpinEnd(price: IPrice): void;
    onClose(): void;
}

export const WheelDialog = (props: IWheelDialogProps) => {
    const { isOpen = false, prices, color, onSpinEnd, onClose } = props;
    const [mustSpin, setMustSpin] = useState<boolean>(false);
    const [priceNumber, setPrizeNumber] = useState(0);

    if (!prices) {
        return <></>;
    }

    let priceData = prices.items.map((x: IPrice) => {
        return {
            option: x.name,
        };
    });

    while (priceData.length < 9) {
        priceData = priceData.concat(priceData);
    }

    const priceLength = prices?.items.length ?? 0;

    const handleSpinClick = () => {
        const newPrizeNumber = Math.floor(Math.random() * priceLength);
        setPrizeNumber(newPrizeNumber);
        setMustSpin(true);
    };

    const handleSpinEnd = () => {
        onSpinEnd(prices.items[priceNumber]);
    };

    return (
        <Dialog open={isOpen} fullWidth onClose={onClose}>
            <div className="spacing padding all triple flex justify-content-center align-items-center">
                <Wheel
                    mustStartSpinning={mustSpin}
                    prizeNumber={priceNumber}
                    data={priceData}
                    backgroundColors={['#3e3e3e', color]}
                    textColors={['#ffffff']}
                    onStopSpinning={() => handleSpinEnd()}
                />
                {!mustSpin && (
                    <Button
                        variant="contained"
                        style={{ backgroundColor: color }}
                        onClick={() => handleSpinClick()}
                        className="spacing margin top triple pulseButton"
                    >
                        Drehen!
                    </Button>
                )}
            </div>
        </Dialog>
    );
};
