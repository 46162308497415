import { getResponse, updateState } from '@intermedia/core/StoreManager';
import { FacilityAction, FacilityActions } from 'actions/FacilityActionCreator';
import { IFacility } from 'models/domain/facility/IFacility';

import { AuthenticationAction, AuthenticationActions } from '../actions/AuthenticationActionCreator';
import { UserAction, UserActions } from '../actions/UserActionCreator';
import { IUser } from '../models/domain/identity';

export interface IUserState {
    user: IUser | undefined;
    facility: IFacility | undefined;
}

const UserDefault: IUserState = {
    user: undefined,
    facility: undefined,
};

export function UserReducer(state: IUserState = UserDefault, action: AuthenticationAction | UserAction | FacilityAction): IUserState {
    switch (action.type) {
        case FacilityActions.GET_FACILITY_RESPONSE: {
            const facility = getResponse(action);

            return updateState(state, { facility });
        }

        case AuthenticationActions.SUBMIT_LOGIN_RESPONSE: {
            const user = getResponse(action);

            // eslint-disable-next-line no-console
            console.log('Reducer: ', user);

            return updateState(state, { user });
        }
        case UserActions.DELETE_USER_RESPONSE:
        case AuthenticationActions.SUBMIT_LOGOUT_RESPONSE:
        case AuthenticationActions.SUBMIT_LOGOUT_FAILED: {
            return updateState(state, { user: undefined });
        }
        default:
            return state;
    }
}
