import { faFacebook, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faGift } from '@fortawesome/pro-solid-svg-icons';
import { Button, CircularProgress, Typography } from '@mui/material';
import Icon from 'components/Icon';
import { IFacility } from 'models/domain/facility/IFacility';
import { IPrice } from 'models/domain/price/IPrice';
import { useEffect, useState } from 'react';
import { generateWhatsappLink } from 'services/WhatsappLinkService/WhatsappLinkService';

import { WhatsappDialog } from './components/WhatsappDialog';
import { WheelDialog } from './components/WheelDialog';

interface IFacilityViewProps extends IFacility {
    loading: boolean;
    testmode?: boolean;
}

export const FacilityView = (props: IFacilityViewProps) => {
    const {
        loading,
        primaryColor,
        name,
        description,
        wlanCode,
        wlanName,
        customLinks,
        twitterLink,
        facebookLink,
        instagramLink,
        prices,
        testmode = false,
    } = props;
    const [wheelOpen, setWheelOpen] = useState<boolean>(false);
    const [win, setWin] = useState<IPrice>();

    const openUrl = (url: string) => {
        window.open(url, '_self');
    };

    useEffect(() => {
        // eslint-disable-next-line no-console
        console.log('rerender facility');
    }, []);

    // eslint-disable-next-line no-console
    console.log('redender');

    if (loading) {
        return (
            <div className="flex justify-content-center align-items-center">
                <CircularProgress />
            </div>
        );
    } else {
        return (
            <div className="flex align-items-center">
                <div className="flex align-items-center spacing padding all triple" style={{ maxWidth: 800, width: '100%' }}>
                    <Typography variant="h4" style={{ textAlign: 'center' }}>
                        {name}
                    </Typography>
                    <Typography variant="caption" style={{ textAlign: 'center' }}>
                        {description}
                    </Typography>
                    <div style={{ textAlign: 'center' }}>{description}</div>
                    <div className="flex justify-content-center align-items-center" style={{ flexGrow: 'revert' }}>
                        <Typography variant="overline">W-Lan</Typography>
                        <div className="flex row">
                            <div>{wlanName}</div>
                            <div> - </div>
                            <div>{wlanCode}</div>
                        </div>
                    </div>
                    <div className="flex" style={{ width: '100%' }}>
                        <Button
                            variant="contained"
                            style={{ backgroundColor: primaryColor }}
                            // onClick={() => setWheelOpen(true)}
                            className="spacing margin top double pulseButton"
                            startIcon={<Icon icon={faGift} />}
                        >
                            Kostenloses Geschenk sichern!
                        </Button>
                        {customLinks.items.map((x) => (
                            <Button variant="outlined" onClick={() => openUrl(x.link)} className="spacing margin top double" key={x.id}>
                                {x.name}
                            </Button>
                        ))}
                        <Typography style={{ textAlign: 'center' }} variant="caption" className="spacing margin top triple">
                            Du findest uns auf:
                        </Typography>
                        {facebookLink && (
                            <Button
                                variant="contained"
                                onClick={() => openUrl(facebookLink ?? '')}
                                className="spacing margin top double"
                                startIcon={<Icon icon={faFacebook} />}
                            >
                                Facebook
                            </Button>
                        )}
                        {instagramLink && (
                            <Button
                                variant="contained"
                                // style={{ backgroundColor: facility?.primaryColor }}
                                onClick={() => openUrl(instagramLink ?? '')}
                                className="spacing margin top double"
                                startIcon={<Icon icon={faInstagram} />}
                            >
                                Instagram
                            </Button>
                        )}
                        {twitterLink && (
                            <Button
                                variant="contained"
                                onClick={() => openUrl(twitterLink ?? '')}
                                className="spacing margin top double"
                                startIcon={<Icon icon={faTwitter} />}
                            >
                                Twitter
                            </Button>
                        )}
                    </div>
                    <div className="flex row">
                        <a href="/impressum">
                            <Typography variant="overline" className="spacing margin right">
                                Impressum
                            </Typography>
                        </a>
                        <a href="/datenschutz">
                            <Typography variant="overline">Datenschutz</Typography>
                        </a>
                    </div>
                    {!testmode && (
                        <>
                            {Boolean(win) && (
                                <WhatsappDialog isOpen={Boolean(win)} link={generateWhatsappLink(win, name)} onClose={() => setWin(undefined)} />
                            )}
                            {wheelOpen && (
                                <WheelDialog
                                    isOpen={true}
                                    prices={prices}
                                    color={primaryColor ?? 'red'}
                                    onSpinEnd={(prize) => setWin(prize)}
                                    onClose={() => setWheelOpen(false)}
                                />
                            )}
                        </>
                    )}
                </div>
            </div>
        );
    }
};
