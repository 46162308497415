import { faExternalLink } from '@fortawesome/pro-light-svg-icons';
import { Paper, Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';

import Icon from '../../../components/Icon';

export interface IDashboardTileProps {
    title: string;
    values: string[];
    isLoading?: boolean;
    onClick?(): void;
}

export const DashboardTile = (props: IDashboardTileProps) => {
    const { title, values, onClick, isLoading } = props;

    return (
        <div className="flex no-grow spacing margin bottom double">
            <Paper
                className={`${onClick && !isLoading && 'pointer'} flex no-grow spacing padding double all`}
                style={{ position: 'relative' }}
                onClick={onClick && !isLoading ? onClick : undefined}
            >
                {onClick && !isLoading && <Icon style={{ position: 'absolute', right: 16 }} icon={faExternalLink} />}
                {values.map((value) => (
                    <Typography key={value} variant="h5">
                        {isLoading ? <Skeleton width="80%" /> : value}
                    </Typography>
                ))}
                <Typography variant="overline" className="spacing padding top" style={{ lineHeight: 'normal' }}>
                    {title}
                </Typography>
            </Paper>
        </div>
    );
};

export default DashboardTile;
