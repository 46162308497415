import { IResourceDictionary } from './IResourceDicitionary';

export type Language = 'de' | 'en';
export enum Languages {
    DE = 'de',
    EN = 'en',
}

const LanguaeLabelMap = {
    [Languages.DE]: 'Deutsch',
    [Languages.EN]: 'Englisch',
};

export const formatLanguage = (lang: Language) => LanguaeLabelMap[lang] ?? '';

export type ILanguageDictionary = Record<Language, IResourceDictionary>;

export default ILanguageDictionary;
