import './MainView.scss';

import { CircularProgress, Divider } from '@mui/material';
import { Box } from '@mui/system';
import { memo, Suspense, useState } from 'react';
import { useRoutes } from 'react-router';

import LogoOnly from '../../assets/images/logos/LogoFullGreyscale.svg';
import { MainRouteConfig } from '../../config/routes/MainRouteConfig';
import Header from '../components/Header';
import SideBar from '../components/SideBar/SideBar';

export interface IMainViewProps {}

export const MainView = memo((props: IMainViewProps) => {
    const [isSideBarExtended, setIsSideBarExtended] = useState<boolean>(false);
    const handleExtendSideBar = () => setIsSideBarExtended(true);
    const handleCloseSideBar = () => setIsSideBarExtended(false);

    const mainRoutes = useRoutes(MainRouteConfig);

    return (
        <main className="flex main">
            <SideBar logo={LogoOnly} handleClose={handleCloseSideBar} isOpen={isSideBarExtended} />
            <section className="flex">
                <Header handleDrawerOpen={handleExtendSideBar} open={isSideBarExtended} />
                <Divider />
                <Box sx={{ flexGrow: 1, p: 2 }} className="flex">
                    <Suspense
                        fallback={
                            <div className="flex">
                                <CircularProgress color="secondary" />
                            </div>
                        }
                    >
                        {mainRoutes}
                    </Suspense>
                </Box>
            </section>
        </main>
    );
});

export default MainView;
