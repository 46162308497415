import { ActionCreator, IBaseAction } from '@intermedia/core/StoreManager';

import { IBreadCrumb } from '../models/IBreadCrumb';

export enum BreadCrumbActions {
    ADD_BREADCRUMB = 'ADD_BREADCRUMB',
    SET_BREADCRUMBS = 'SET_BREADCRUMBS',
    RESET_BREADCRUMBS = 'RESET_BREADCRUMBS',
    REMOVE_BREADCRUMB = 'REMOVE_BREADCRUMB',
}

type IAddBreadcrumbAction = IBaseAction<BreadCrumbActions.ADD_BREADCRUMB, IBreadCrumb | IBreadCrumb[]>;
type ISetBreadcrumbsAction = IBaseAction<BreadCrumbActions.SET_BREADCRUMBS, IBreadCrumb[]>;
type IResetBreadcrumbsAction = IBaseAction<BreadCrumbActions.RESET_BREADCRUMBS>;
type IRemoveBreadcrumbAction = IBaseAction<BreadCrumbActions.REMOVE_BREADCRUMB, IBreadCrumb | IBreadCrumb[]>;

export type BreadCrumbAction =
    | IAddBreadcrumbAction
    | ISetBreadcrumbsAction
    | IResetBreadcrumbsAction
    | IRemoveBreadcrumbAction;

export class BreadCrumbActionCreator {
    public static addBreadcrumb(breadcrumb: IBreadCrumb | IBreadCrumb[]): IAddBreadcrumbAction {
        return ActionCreator.createAction<BreadCrumbActions.ADD_BREADCRUMB, IBreadCrumb | IBreadCrumb[]>(
            BreadCrumbActions.ADD_BREADCRUMB,
            breadcrumb
        );
    }

    public static setBreadcrumbs(breadcrumbs: IBreadCrumb[]): ISetBreadcrumbsAction {
        return ActionCreator.createAction<BreadCrumbActions.SET_BREADCRUMBS, IBreadCrumb[]>(
            BreadCrumbActions.SET_BREADCRUMBS,
            breadcrumbs
        );
    }

    public static resetBreadcrumbs(): IResetBreadcrumbsAction {
        return ActionCreator.createAction<BreadCrumbActions.RESET_BREADCRUMBS>(BreadCrumbActions.RESET_BREADCRUMBS);
    }

    public static removeBreadcrumb(breadcrumb: IBreadCrumb | IBreadCrumb[]): IRemoveBreadcrumbAction {
        return ActionCreator.createAction<BreadCrumbActions.REMOVE_BREADCRUMB, IBreadCrumb | IBreadCrumb[]>(
            BreadCrumbActions.REMOVE_BREADCRUMB,
            breadcrumb
        );
    }
}

export default BreadCrumbActionCreator;
