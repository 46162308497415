// const OwnProfile = lazy(() => import('../own-profile/OwnProfile'));
// const RestaurantView = lazy(() => import('./restaurant/RestaurantView'));
// const MenuCardView = lazy(() => import('./menuCard/MenuCardView'));

export const RouteConfig = {
    Login: {
        root: '/login',
    },

    Main: {
        root: '/',
        configuration: {
            root: '/configuration',
            self: '/configuration/:id',
        },
        page: {
            root: '/p',
            self: '/p/:id',
        },
        impressum: {
            root: '/impressum',
        },
        datenschutz: {
            root: '/datenschutz',
        },
    },
};
