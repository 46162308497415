import { IDispatch, IMiddlewareAPI } from '@intermedia/core/StoreManager';
import { FacilityAction, FacilityActions } from 'actions/FacilityActionCreator';
import { OptionsObject } from 'notistack';

import { AuthenticationAction, AuthenticationActions } from '../../../actions/AuthenticationActionCreator';
import { UserAction, UserActions } from '../../../actions/UserActionCreator';
import { IState } from '../../../models/state/IState';
import NotificationActionCreator from '../actions/NotificationActionCreator';

type Actions = AuthenticationAction | UserAction | FacilityAction;

export function NotificationMiddleware(middlewareAPI: IMiddlewareAPI<IState>) {
    const { dispatch } = middlewareAPI;
    const enqueueSnackbar = (message: string, options?: OptionsObject) =>
        dispatch(
            NotificationActionCreator.enqueueSnackbar({
                key: String(new Date().getTime() + Math.random()),
                message: message,
                options,
            })
        );

    return (next: IDispatch) => (action: Actions) => {
        switch (action.type) {
            case UserActions.GET_USER_FAILED: {
                enqueueSnackbar('User konnte nicht gefunden werden. Eventuell wurde er gelöscht.', {
                    variant: 'error',
                });
                break;
            }

            case FacilityActions.PATCH_FACILITY_RESPONSE: {
                enqueueSnackbar('Deine Änderungen sind Live!', { variant: 'success' });
                break;
            }

            case AuthenticationActions.CHANGE_PASSWORD_RESPONSE: {
                enqueueSnackbar('Dein Passwort wurde erfolgreich geändert!', { variant: 'success' });
                break;
            }
            case AuthenticationActions.CHANGE_PASSWORD_FAILED: {
                enqueueSnackbar('Beim ändern deines Passwortes ist ein Fehler aufgetreten!', { variant: 'error' });
                break;
            }

            case AuthenticationActions.RESET_PASSWORD_RESPONSE: {
                enqueueSnackbar('Dein Passwort wurde erfolgreich zurückgesetzt!', { variant: 'success' });
                break;
            }
            case AuthenticationActions.RESET_PASSWORD_FAILED: {
                enqueueSnackbar('Beim zurücksetzen deines Passwortes ist ein Fehler aufgetreten!', {
                    variant: 'error',
                });
                break;
            }

            case AuthenticationActions.SUBMIT_REGISTER_RESPONSE: {
                enqueueSnackbar('Erfolgreich registriert!', { variant: 'success' });
                break;
            }
            case AuthenticationActions.SUBMIT_REGISTER_FAILED: {
                // case RegisterState.FAILED:
                //     case RegisterState.EMAIL_ALREADY_EXISTS: {
                enqueueSnackbar('Bei der Registrierung ist ein Fehler aufgetreten!', { variant: 'error' });
                break;
                //     }
                // }
                // break;
            }
            case AuthenticationActions.RESEND_CONFIRM_EMAIL_RESPONSE: {
                enqueueSnackbar('Deine Verifikations E-Mail wurde erneut gesendet, bitte überprüfe dein Postfach (auch im Spam Ordner)', {
                    variant: 'success',
                });
                break;
            }
            case AuthenticationActions.RESEND_CONFIRM_EMAIL_FAILED: {
                enqueueSnackbar('Die Verifikations E-Mail konnte nicht erneut gesendet werden.');
                break;
            }
            case AuthenticationActions.SUBMIT_LOGIN_FAILED: {
                enqueueSnackbar('Es ist ein Fehler beim Login vorgefallen!', { variant: 'error' });
                break;
            }

            default:
                break;
        }

        return next(action);
    };
}

export default NotificationMiddleware;
