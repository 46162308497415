import { Button, Chip, Typography } from '@mui/material';
import ReactPlayer from 'react-player';

import BG from '../../../assets/images/stock/2.jpg';
import { LPSectionWrapper } from '../components/LandingpageWrapper/LandingpageWrapper';
import { LPChecklistItem } from '../components/LPChecklistItem/LPChecklistItem';
import { LPHeader } from '../components/LPHeader/LPHeader';
import { MainLandingHelmet } from './MainLanding.Helmet';

export const MainLanding = () => {
    const handleClickOnCalendly = () => {
        window.open('https://calendly.com/meetintermedia/gespraech', '_self');
    };

    return (
        <>
            <div style={{ background: `url(${BG}) no-repeat center center fixed`, backgroundSize: 'cover', height: '80vh' }}>
                <div style={{ background: 'rgba(255,255,255,0.85)', height: '100%' }}>
                    <MainLandingHelmet />
                    <LPHeader onClick={handleClickOnCalendly} />
                    <LPSectionWrapper className="flex justify-content-center">
                        <>
                            <div className="flex justify-content-center align-items-center spacing margin bottom double">
                                <Chip
                                    label="Für Gastronomen"
                                    className="flex"
                                    style={{ backgroundColor: '#2FA155', fontWeight: 'bold', color: 'white' }}
                                />
                            </div>
                            <div className="flex row justify-content-center">
                                <div className="title">
                                    Du bist nur einen Schritt von <span className="color-green">höheren Umsätzen</span> und einer{' '}
                                    <span className="color-green">besseren Bindung deiner Gäste</span> entfernt.
                                </div>
                            </div>
                            <Typography variant="body1" style={{ textAlign: 'center', fontWeight: 'bold' }} className="spacing margin top double">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras id fermentum tortor. Maecenas et magna leo.
                            </Typography>
                        </>
                    </LPSectionWrapper>
                    <LPSectionWrapper className="flex row justify-content-space-between">
                        <>
                            <div style={{ width: '80%' }}>
                                <ReactPlayer url="https://www.youtube.com/watch?v=ysz5S6PUM-U" width={'100%'} />
                            </div>
                            <div style={{ width: 40 }}></div>
                            <div className="flex justify-content-start align-items-start spacing padding left double">
                                <LPChecklistItem text="Du erhältst nachhaltig mehr positive Bewertungen auf Online-Portalen" />
                                <LPChecklistItem text="Informiere deine Zielgruppe über Sonderangebote oder Events ohne E-Mail Marketing" />
                                <LPChecklistItem text="Digitalisiere deine Lokalität" />
                                <LPChecklistItem text="Erhalte individuelle Betreuung, welche auf DEIN Geschäft abgestimmt ist" />
                                <div className="flex justify-content-center align-items-center full-width">
                                    <Button variant="contained" color="primary" className="button-cta orange pulse" onClick={handleClickOnCalendly}>
                                        Jetzt unverbindliches
                                        <br />
                                        Erstgespräch buchen
                                    </Button>
                                    <Typography variant="overline">100% Unverbindlich</Typography>
                                </div>
                            </div>
                        </>
                    </LPSectionWrapper>
                </div>
            </div>
            <LPSectionWrapper className="flex justify-content-center align-items-center spacing margin top triple">
                <Typography variant="h5">Wir kennen dein Problem!</Typography>
            </LPSectionWrapper>
        </>
    );
};
