import { ApexOptions } from 'apexcharts';
import { DateFormats, formatDate } from 'core/formatDate';
import { IFacilityStatisticsResponse } from 'models/contracts/responses/Statistics/FacilityStatsticsResponse';
import Chart from 'react-apexcharts';

interface IWeekDrawbackGraph {
    data: IFacilityStatisticsResponse;
}

export const WeekDrawbackGraph = (props: IWeekDrawbackGraph) => {
    const { data } = props;

    const chartOptions: ApexOptions = {
        chart: {
            type: 'area',
            id: 'basic-bar',
            zoom: {
                enabled: false,
            },
        },

        stroke: {
            curve: 'smooth',
        },
        xaxis: {
            categories: data?.pageViewDrawback.map((x) => formatDate(x.date, DateFormats.SHORT_DATE).toString()),
        },
    };

    const chartSeries: ApexAxisChartSeries = [
        {
            name: 'series-1',
            data: data?.pageViewDrawback.map((x) => x.value ?? 0),
        },
    ];

    return <Chart options={chartOptions} series={chartSeries} type="area" width="500" />;
};
