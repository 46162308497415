import { IReducersMapObject, ReducerManager } from '@intermedia/core/StoreManager';

import { IState } from '../models/state/IState';
import BreadCrumbReducer from '../services/BreadCrumbs/reducer/BreadCrumbReducer';
import NotificationReducer from '../services/Notifications/reducer/NotificationReducer';
import AuthenticationReducer from './Authorization.reducer';
import DashboardReducer from './Dashboard.reducer';
import { UserReducer } from './User.reducer';

export const AppReducers: IReducersMapObject<IState> = {
    authorization: AuthenticationReducer,
    notifications: NotificationReducer,
    breadCrumbs: BreadCrumbReducer,
    user: UserReducer,
    dashboard: DashboardReducer,
};

export const AppReducer = ReducerManager.combine<IState>(AppReducers);

export default AppReducer;
