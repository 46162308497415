import './UserImage.scss';

import Avatar, { AvatarProps } from '@mui/material/Avatar';
import { memo } from 'react';

//import { CSSProperties, memo } from 'react';

export interface IUserImageProps extends AvatarProps {
    src?: string;
}

/* const iconStyle: CSSProperties = {
    width: '70%',
    height: '70%',
};
 */
export const UserImage = memo((props: IUserImageProps) => {
    const { src, className, ...rest } = props;

    return (
        <Avatar {...rest} className={className} src={src}>
            {props.children}
        </Avatar>
    );
});

export default UserImage;
