import Button, { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

export interface ILoadingButtonProps extends ButtonProps {
    isLoading?: boolean;
}

export const LoadingButton = (props: ILoadingButtonProps) => {
    const { isLoading, disabled, startIcon, ...rest } = props;

    return (
        <Button
            {...rest}
            disabled={isLoading || disabled}
            startIcon={isLoading ? <CircularProgress size={20} /> : startIcon}
        />
    );
};

export default LoadingButton;
