import { FormControl } from '@mui/material';
import MuiTextField, { OutlinedTextFieldProps, TextFieldProps } from '@mui/material/TextField';
import { CSSProperties, memo } from 'react';

export interface ITextFieldProps extends Omit<OutlinedTextFieldProps, 'variant'> {
    rootClassName?: string;
    rootStyle?: CSSProperties;
    variant?: TextFieldProps['variant'];
}

export const TextField = memo((props: ITextFieldProps) => {
    const { label, rootClassName, rootStyle, fullWidth = true, variant, ...rest } = props;

    const textField = (
        <MuiTextField
            // InputLabelProps={{ style: { color: '#000' } }}
            label={label}
            {...rest}
            variant={variant}
            fullWidth={fullWidth}
        />
    );

    if (!label) {
        return textField;
    }

    return (
        <FormControl style={rootStyle} className={rootClassName} fullWidth={fullWidth}>
            {textField}
        </FormControl>
    );
});

export default TextField;
