import IIFE from '@intermedia/core/common/helper/IIFE';
import { DirectInteractionActionCreator } from 'actions/DirectInteractionActionCreator';
import { defaultFacility, IFacility } from 'models/domain/facility/IFacility';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { FacilityView } from './Facility';

export const FacilityWrapper = () => {
    const { id = '' } = useParams<'id'>();
    const [facility, setFacility] = useState<IFacility>(defaultFacility);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        IIFE(async () => {
            setLoading(true);
            // eslint-disable-next-line no-console
            console.log('Fetch');
            const x = await DirectInteractionActionCreator.getPageContent(id);
            if (x !== null) {
                setFacility(x);
            }
            setLoading(false);
        });
    }, [id]);

    return <FacilityView {...facility} loading={loading} />;
};
