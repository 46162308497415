import { IDuration } from '@intermedia/core/common/date/ImDuration';
import { isNumber, isString } from '@intermedia/core/common/typeguards';
import format from 'date-fns/format';
import de from 'date-fns/locale/de';

export enum DateFormats {
    TIME,
    SHORT_DATE,
    DATE,
    DATE_TIME,
    LONG_DAY,
    MONTH,
}

export function formatDate(date: string | number | Date = new Date(), dateFormat?: DateFormats) {
    let dateInstance: Date = new Date();

    if (isString(date) || isNumber(date)) {
        dateInstance = new Date(date);
    } else {
        dateInstance = date;
    }

    let formatString;

    switch (dateFormat) {
        case DateFormats.TIME: {
            formatString = 'p';
            break;
        }
        case DateFormats.SHORT_DATE:
            formatString = 'dd.MM';
            break;
        case DateFormats.DATE_TIME:
            formatString = 'dd.MM.yyyy HH:mm';
            break;
        case DateFormats.LONG_DAY:
            formatString = 'EEEE, d LLLL';
            break;
        case DateFormats.MONTH: {
            formatString = 'LLLL';
            break;
        }
        case DateFormats.DATE:
        default:
            formatString = 'dd.MM.yyyy';
            break;
    }

    return format(dateInstance, formatString, { locale: de, weekStartsOn: 1 });
}

export function padDateString(date: number) {
    return String(date).padStart(2, '0');
}

export function getUnifiedDateFromISO(date: Date) {
    const year = date.getFullYear();
    const month = padDateString(date.getMonth() + 1);
    const day = padDateString(date.getDate());

    return `${year}-${month}-${day}`;
    // return date.toISOString().split('T')[0];
}

export function stringifyDuration(duration: IDuration) {
    const { days = 0, hours = 0, minutes = 0, seconds = 0 } = duration;
    const parsedDuration = {
        days: days.toString(),
        hours: padDateString(hours),
        minutes: padDateString(minutes),
        seconds: padDateString(seconds),
    };

    let stringifiedDuration = `${parsedDuration.hours}:${parsedDuration.minutes}:${parsedDuration.seconds} `;

    if (days > 0) {
        stringifiedDuration = `${parsedDuration.days}.${stringifiedDuration}`;
    }

    return stringifiedDuration;
}

export function parseDuration(duration: string) {
    // "1.00:00:00".split('.')[1].

    let durationCopy = duration.toString();

    const parsedDuration: IDuration = { days: 0, hours: 0, minutes: 0, seconds: 0 };

    if (durationCopy.indexOf('.') !== -1) {
        const [days, time] = durationCopy.split('.');
        durationCopy = time;
        parsedDuration.days = parseInt(days, 10);
    }

    const [hours, minutes, seconds] = durationCopy.split(':');

    parsedDuration.hours = parseInt(hours, 10);
    parsedDuration.minutes = parseInt(minutes, 10);
    parsedDuration.seconds = parseInt(seconds, 10);

    return parsedDuration;
}

export function formatDuration(durationString: string) {
    const duration = parseDuration(durationString);
    const { days = 0, hours = 0, minutes = 0, seconds = 0 } = duration;
    return `${days} Tag/e ${padDateString(hours)}:${padDateString(minutes)}:${padDateString(seconds)}`;
}
