import './Configuration.scss';

import IIFE from '@intermedia/core/common/helper/IIFE';
import { useDispatch, useSelector } from '@intermedia/core/StoreManager';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { Box, Button, CircularProgress, Divider, Paper, Tab, Tabs, Typography } from '@mui/material';
import { DirectInteractionActionCreator } from 'actions/DirectInteractionActionCreator';
import FacilityActionCreator from 'actions/FacilityActionCreator';
import { ColorPickerComponent } from 'components/ColorPickerComponent/ColorPickerComponent';
import TextField from 'components/TextField';
import { defaultFacility, IFacility } from 'models/domain/facility/IFacility';
import { useEffect, useState } from 'react';
import { getUser } from 'selectors';
import useBreadcrumb from 'services/BreadCrumbs/core/useBreadcrumb';
import { FacilityView } from 'views/Facility/Facility';

export const Configuration = () => {
    const [localFacility, setLocalFacility] = useState<IFacility>(defaultFacility);
    // const [facility, setLocalFacility] = useState<IFacility>(defaultFacility);
    const [activeTab, setActiveTab] = useState<number>(0);
    const [isLoading, setLoading] = useState<boolean>(false);

    useBreadcrumb([{ text: 'Persönliche Seite anpassen' }]);
    const dispatch = useDispatch();

    const user = useSelector(getUser);

    useEffect(() => {
        IIFE(async () => {
            if (user) {
                setLoading(true);
                const x = await DirectInteractionActionCreator.getPageContent(user.facilityId);
                if (x !== null) {
                    setLocalFacility(x);
                }
                setLoading(false);
            }
        });
    }, [dispatch, user]);

    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue);
    };

    const handleSaveFacility = async () => {
        setLoading(true);
        await dispatch(FacilityActionCreator.patchFacility(localFacility.id, localFacility));
        setLoading(false);
    };

    if (isLoading) {
        return (
            <section className="flex configurationWrapper">
                <Paper className="flex justify-content-center align-items-center">
                    <CircularProgress />
                </Paper>
                <Paper className="flex justify-content-center align-items-center">
                    <CircularProgress />
                </Paper>
            </section>
        );
    }

    return (
        <>
            <TabContext value={activeTab.toString()}>
                <section className="flex configurationWrapper">
                    <Paper className="spacing padding triple all" style={{ position: 'relative' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={activeTab} onChange={handleChangeTab} aria-label="basic tabs example">
                                <Tab label="Farben" {...a11yProps(0)} />
                                <Tab label="Allgemeines" {...a11yProps(1)} />
                                <Tab label="Social Media" {...a11yProps(2)} />
                                <Tab label="Eigene Links" {...a11yProps(3)} />
                            </Tabs>
                        </Box>
                        <TabPanel value="0">
                            <ColorPickerComponent
                                color={localFacility.primaryColor}
                                onChange={(e) => setLocalFacility({ ...localFacility, primaryColor: e })}
                            />
                        </TabPanel>
                        <TabPanel value="1">
                            <TextField
                                label="Beschreibung"
                                className="spacing margin top double"
                                value={localFacility.description}
                                onChange={(e) => setLocalFacility({ ...localFacility, description: e.target.value })}
                            />
                            <Divider className="spacing margin top bottom triple" />
                            <Typography variant="h6">Wlan Daten</Typography>
                            <div className="wlan">
                                <TextField
                                    label="Wlan Name"
                                    className="spacing margin top double"
                                    value={localFacility.wlanName}
                                    onChange={(e) => setLocalFacility({ ...localFacility, wlanName: e.target.value })}
                                />
                                <TextField
                                    label="Wlan Passwort"
                                    className="spacing margin top double"
                                    value={localFacility.wlanCode}
                                    onChange={(e) => setLocalFacility({ ...localFacility, wlanCode: e.target.value })}
                                />
                            </div>
                        </TabPanel>
                        {/* <Divider className="spacing margin top bottom triple" /> */}
                        <TabPanel value="2">
                            <Typography variant="h6">Social-Media Links</Typography>
                            <TextField
                                label="Facebook Link"
                                className="spacing margin top double"
                                value={localFacility.facebookLink}
                                onChange={(e) => setLocalFacility({ ...localFacility, facebookLink: e.target.value })}
                            />
                            <TextField
                                label="Instagram Link"
                                className="spacing margin top double"
                                value={localFacility.instagramLink}
                                onChange={(e) => setLocalFacility({ ...localFacility, instagramLink: e.target.value })}
                            />
                            <TextField
                                label="Twitter Link"
                                className="spacing margin top double"
                                value={localFacility.twitterLink}
                                onChange={(e) => setLocalFacility({ ...localFacility, twitterLink: e.target.value })}
                            />
                        </TabPanel>
                        <div style={{ position: 'absolute', bottom: 8, right: 8 }}>
                            <Button variant="contained" color="primary" onClick={() => handleSaveFacility()}>
                                Speichern
                            </Button>
                        </div>
                    </Paper>

                    <Paper className="spacing padding triple all" style={{ userSelect: 'none', pointerEvents: 'none' }}>
                        <FacilityView {...localFacility} loading={false} testmode />
                    </Paper>
                </section>
            </TabContext>
        </>
    );
};

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
