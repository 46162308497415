import { isArray, isEqual } from '@intermedia/core/common/typeguards';

import { BreadCrumbAction, BreadCrumbActions } from '../actions/BreadCrumbActionCreator';
import { IBreadCrumb } from '../models/IBreadCrumb';

export interface IBreadCrumbState {
    breadCrumbs: IBreadCrumb[];
}

const defaultValue: IBreadCrumbState = {
    breadCrumbs: [],
};

type ShellReducerActions = BreadCrumbAction;

export function BreadCrumbReducer(
    state: IBreadCrumbState = defaultValue,
    action: ShellReducerActions
): IBreadCrumbState {
    switch (action.type) {
        case BreadCrumbActions.ADD_BREADCRUMB: {
            const newBreadCrumbs = isArray(action.payload)
                ? [...state.breadCrumbs, ...action.payload]
                : [...state.breadCrumbs, action.payload];
            return { ...state, breadCrumbs: newBreadCrumbs };
        }
        case BreadCrumbActions.SET_BREADCRUMBS: {
            return { ...state, breadCrumbs: action.payload };
        }
        case BreadCrumbActions.REMOVE_BREADCRUMB: {
            if (isArray(action.payload)) {
                let newBreadCrumbs = state.breadCrumbs;

                for (const breadcrumb of action.payload) {
                    newBreadCrumbs = state.breadCrumbs.filter((b) => !isEqual(b, breadcrumb));
                }

                return { ...state, breadCrumbs: newBreadCrumbs };
            } else {
                return { ...state, breadCrumbs: state.breadCrumbs.filter((b) => !isEqual(b, action.payload)) };
            }
        }
        case BreadCrumbActions.RESET_BREADCRUMBS: {
            return { ...state, breadCrumbs: [] };
        }
        default:
            return state;
    }
}

export default BreadCrumbReducer;
