import { faMoon, faSignOut, faUserCog } from '@fortawesome/pro-light-svg-icons';
import { useDispatch, useSelector } from '@intermedia/core/StoreManager';
import { ListItemIcon, Menu, MenuItem, Typography, useTheme } from '@mui/material';
import React, { useCallback, useContext, useState } from 'react';
import { Link } from 'react-router-dom';

import AuthenticationActionCreator from '../../../actions/AuthenticationActionCreator';
import Icon from '../../../components/Icon';
import { UserImage } from '../../../components/UserImage';
import { OWN_PROFILE_PATH } from '../../../config/Paths';
import { getUser } from '../../../selectors';
import { ThemeContext } from '../../../theme/ThemeContext';

export interface IHeaderUserProps {
    //user: IUser;
}

export const HeaderUser = (props: IHeaderUserProps) => {
    //const { user } = props;
    const [anchorEl, setAnchorEl] = useState<null | HTMLDivElement>(null);
    const { setTheme, type } = useContext(ThemeContext);
    const user = useSelector(getUser);

    const theme = useTheme();
    const dispatch = useDispatch();

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => setAnchorEl(null);

    const toggleTheme = () => {
        setTheme(type === 'dark' ? 'light' : 'dark');
    };

    const handleSignout = useCallback(() => {
        dispatch(AuthenticationActionCreator.submitLogout());
    }, [dispatch]);

    return (
        <>
            <div onClick={handleClick} className="flex pointer row no-grow spacing padding left right double centered">
                <UserImage src={user?.profileImage?.href} className="spacing margin right" />
                <Typography variant="body1">
                    {user?.firstName} {user?.name}
                </Typography>
            </div>
            <Menu
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorEl={anchorEl}
            >
                <MenuItem component={Link} to={OWN_PROFILE_PATH}>
                    <ListItemIcon>
                        <Icon icon={faUserCog} />
                    </ListItemIcon>
                    Dein Profil
                </MenuItem>
                <MenuItem onClick={toggleTheme}>
                    <ListItemIcon>
                        <Icon icon={faMoon} />
                    </ListItemIcon>
                    Nachtmodus
                </MenuItem>
                <MenuItem style={{ color: theme.palette.error.main }} onClick={handleSignout}>
                    <ListItemIcon>
                        <Icon icon={faSignOut} color="error" />
                    </ListItemIcon>
                    Abmelden
                </MenuItem>
            </Menu>
        </>
    );
};

export default HeaderUser;
