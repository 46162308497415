import FetchService from '@intermedia/core/FetchService';
import { ActionCreator, createAsyncActionEnum, IAsyncAction } from '@intermedia/core/StoreManager';
import { IFacilityStatisticsResponse } from 'models/contracts/responses/Statistics/FacilityStatsticsResponse';
import { IFacility } from 'models/domain/facility/IFacility';

import ApiRoutes from '../config/ApiRoutes';
import { DeclareAsyncAction } from '../models/state/AsyncActionLiteralCreatorType';

export const FacilityActions = {
    ...createAsyncActionEnum('GET_FACILITY'),
    ...createAsyncActionEnum('PATCH_FACILITY'),
    ...createAsyncActionEnum('GET_FACILITY_STATS'),
} as const;

export type FacilityAction =
    | DeclareAsyncAction<typeof FacilityActions.GET_FACILITY, IFacility>
    | DeclareAsyncAction<typeof FacilityActions.PATCH_FACILITY, IFacility, IFacility>
    | DeclareAsyncAction<typeof FacilityActions.GET_FACILITY_STATS, IFacilityStatisticsResponse>;
// | DeclareAsyncAction<typeof UserActions.GET_USER, IUser>
// | DeclareAsyncAction<typeof UserActions.DELETE_USER, undefined, string>;

export class FacilityActionCreator {
    public static getFacility(id: string): IAsyncAction {
        const href = ApiRoutes.createUrl(ApiRoutes.Administration.Facility.Self(id));

        return ActionCreator.createAsyncAction(
            FacilityActions.GET_FACILITY,
            [FacilityActions.GET_FACILITY_REQUEST, FacilityActions.GET_FACILITY_RESPONSE, FacilityActions.GET_FACILITY_FAILED],
            () => FetchService.get(href)
        );
    }
    public static patchFacility(id: string, data: IFacility): IAsyncAction {
        const href = ApiRoutes.createUrl(ApiRoutes.Administration.Facility.Self(id));

        return ActionCreator.createAsyncAction(
            FacilityActions.PATCH_FACILITY,
            [FacilityActions.PATCH_FACILITY_REQUEST, FacilityActions.PATCH_FACILITY_RESPONSE, FacilityActions.PATCH_FACILITY_FAILED],
            () => FetchService.patch(href, data)
        );
    }
    public static getFacilityStatistics(id: string): IAsyncAction {
        const href = ApiRoutes.createUrl(ApiRoutes.Administration.Facility.Statistics(id));

        return ActionCreator.createAsyncAction(
            FacilityActions.GET_FACILITY_STATS,
            [FacilityActions.GET_FACILITY_STATS_REQUEST, FacilityActions.GET_FACILITY_STATS_RESPONSE, FacilityActions.GET_FACILITY_STATS_FAILED],
            () => FetchService.get(href)
        );
    }
}

export default FacilityActionCreator;
