import './LoginView.scss';

import { useDispatch } from '@intermedia/core/StoreManager';
import { Paper } from '@mui/material';
import { useCallback } from 'react';

import AuthenticationActionCreator, { AuthenticationAction } from '../../../actions/AuthenticationActionCreator';
import logo from '../../../assets/images/logos/logoonlyBlack.png';
import LoginForm from './LoginForm';

export interface ILoginViewProps {}

export function LoginView(props: ILoginViewProps) {
    const dispatch = useDispatch();

    const handleSubmitClick = useCallback(
        (email: string, password: string, rememberLogin: boolean): Promise<AuthenticationAction> => {
            return dispatch(
                AuthenticationActionCreator.submitLogin({ password: password, userName: email })
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ) as any as Promise<AuthenticationAction>;
        },
        [dispatch]
    );

    return (
        <main className="flex justify-content-center align-items-center">
            <Paper
                className="flex no-grow login-paper full-width spacing padding all double justify-content-center"
                elevation={18}
            >
                <div className="flex justify-content-center align-items-center spacing padding all double">
                    <img
                        draggable={false}
                        src={logo}
                        alt="app-logo"
                        style={{
                            height: 50,
                        }}
                    />
                </div>
                <LoginForm onSubmitClick={handleSubmitClick} />
            </Paper>
        </main>
    );
}

export default LoginView;
